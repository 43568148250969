<template>
  <div class="app">
    <Layout>
      <Content class="upload_content">
        <!-- <sellerSider></sellerSider> -->
        <!-- <Content class="upload_content_body">
          <Layout>
            <Header>
              <div class="header-words">
                <h1 class="upload-header-words">发布视频</h1>
              </div>
              <div class="subtitle">
                <span class="subtitle-words">使用抖工公众号,用手机也可以发布视频</span>
              </div>
            </Header>
            <Content class="upload-body">
              <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true
                            v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError"
              >
                <div class="upload-content-zone">
                  <img class="upload-logo" src="../../../assets/images/upload.png"/>
                </div>
                <div class="upload-words">
                  <span class="click-upload-words">点击上传</span>
                  <span class="drag-upload-words">或直接将视频文件拖拽到此区域</span>
                </div>
                <div class="upload-footer">
                  <div class="upload-explains">
                    <div class="upload-explains-title">
                      <span class="title-words">视频格式</span>
                    </div>
                    <div class="upload-explains-detail">
                      <span class="detail-words">支持常用视频格式，推荐使用mp4、wmv</span>
                    </div>
                  </div>
                  <div class="upload-explains">
                    <div class="upload-explains-title">
                      <span class="title-words">视频大小</span>
                    </div>
                    <div class="upload-explains-detail">
                      <span class="detail-words">视频文件大小不超过4G，时长在1分钟以内</span>
                    </div>
                  </div>
                  <div class="upload-explains">
                    <div class="upload-explains-title">
                      <span class="title-words">视频分辨率</span>
                    </div>
                    <div class="upload-explains-detail">
                      <span class="detail-words">分辨率为720p（1280x720）及以上</span>
                    </div>
                  </div>
                </div>
              </vue-dropzone>
            </Content>
          </Layout>
        </Content> -->
        <uploader
          :key="uploader_key"
          :options="options"
          class="uploader-example"
          :autoStart="false"
          @file-success="onFileSuccess"
          @file-added="filesAdded"
        >
          <uploader-unsupport></uploader-unsupport>
          <uploader-drop>
            <uploader-btn :single="true" :attrs="attrs"
              >选择文件</uploader-btn
            >
          </uploader-drop>
          <uploader-list></uploader-list>
        </uploader>
      </Content>
    </Layout>
  </div>
</template>

<script>
import sellerSider from "../../../components/sellerSider";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SparkMD5 from 'spark-md5';

export default {
  name: "upload",
  components: {
    sellerSider,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: "http://134.175.132.191:8082/sapi/chunk/upload",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 300,
        showRemoveLink: true,
        maxNumberOfFiles: 1,
        acceptedFileTypes: "mp4,wmv,avi,rmvb",
        paramName: "uploadFile",
      },
      userInfo: "",
      token: "",
      uploadFile: {},
     uploader_key: new Date().getTime(),
        options: {
          target: '/sapi/chunk/upload',
          testChunks: false,
          query: {
            corpId: JSON.stringify(JSON.parse(localStorage.getItem("user_info")).icorpid)
          }
        },
      attrs: {
        accept: [
          ".png",
          ".jpg",
          ".jpeg",
          ".gif",
          ".bmp",
          ".mp4",
          ".wmv",
          ".avi",
          ".rmvb",
        ],
      },
      uploadFile: null
    };
  },
  created() {},
  mounted() {
    console.log(JSON.stringify(JSON.parse(localStorage.getItem("user_info")).icorpid))
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.token = this.common.getAuth();
      let user = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (user) {
        this.userInfo = escape(this.common.userStr());
      } else {
        this.$Message.error("登录已过期,请重新登录");
        this.$router.push({ name: "login" });
      }

      this.$refs.myVueDropzone.dropzone.options.headers = {
        "Eova-Authorization": this.token,
        user_info: this.userInfo,
      };
    },
    uploadSuccess(file, response) {
      if (response.code == "200") {
        this.uploadFile.name = response.resData.fileName;
        this.uploadFile.size = response.resData.size;
        this.uploadFile.size_show = (response.resData.size / 10000000).toFixed(
          2
        );
        this.uploadFile.ext = response.resData.ext;
        this.uploadFile.frame_show =
          this.CONSTANTS.FRAME_URL + response.resData.frame;
        this.uploadFile.filePath_show =
          this.CONSTANTS.VIDEO_URL + response.resData.fileName;
        this.uploadFile.frame = response.resData.fileName;
        this.uploadFile.filePath = response.resData.file_path;
        this.uploadFile.preview_frame_path =
          response.resData.preview_frame_path;
        this.uploadFile.upload_time = response.resData.upload_time;
        this.uploadFile.id = response.resData.id;
        this.$Message.success("上传成功");
        this.$router.push({
          name: "releaseUpload",
          params: {
            uploadFile: this.uploadFile,
          },
        });
      } else {
        this.$Message.error(response.message);
        this.reload();
      }
    },
    uploadError(file) {
      this.$Message.error("上传失败");
      this.reload();
    },
    onFileSuccess: function (rootFile, file, response, chunk) {
      console.log(rootFile, file, response, chunk)
        // console.log(JSON.parse(response).model);
      },
      computeMD5(file) {
        console.log(file)
        // const loading = this.$loading({
        //   lock: true,
        //   text: '正在计算MD5',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'
        // });
        let fileReader = new FileReader();
        let time = new Date().getTime();
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let currentChunk = 0;
        const chunkSize = 10 * 1024 * 1024;
        let chunks = Math.ceil(file.size / chunkSize);
        let spark = new SparkMD5.ArrayBuffer();
        file.pause();
 
        loadNext();
 
        fileReader.onload = (e => {
          spark.append(e.target.result);
          if (currentChunk < chunks) {
            currentChunk++;
            loadNext();
            this.$nextTick(() => {
              console.log('校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%')
            })
          } else {
            let md5 = spark.end();
            // loading.close();
            this.computeMD5Success(md5, file);
            console.log(`MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${new Date().getTime() - time} ms`);
          }
        });
        fileReader.onerror = function () {
          this.error(`文件${file.name}读取出错，请检查该文件`);
          // loading.close();
          file.cancel();
        };
 
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
        }
      },
      computeMD5Success(md5, file) {
        file.uniqueIdentifier = md5;//把md5值作为文件的识别码
        file.resume();//开始上传
      },
      filesAdded(file, event) {
        //大小判断
        const isLt100M = file.size / 1024 / 1024 < 10;
        if (!isLt100M) {
          this.$message.error(this.$t("error.error_upload_file_max"));
        } else {
          this.computeMD5(file)
        }
      }
  },
};
</script>

<style scoped lang="less">
.uploader-example {
  width: 880px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  background: rgba(251, 250, 248, 1);

  .upload-header-words {
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
    color: #161823;
    margin-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 40px;
    background: #fff;
    margin-top: -2px;

    .subtitle-words {
      font-size: 16px;
      line-height: 22px;
      color: #161823;
      margin-bottom: 20px;
    }
  }

  .upload-body {
    margin-top: 60px;
    background: #fff;

    #dropzone {
      padding: 40px 45px;
      background: #fcfcfc;
      border: 2px dashed rgba(22, 24, 35, 0.06);
      border-radius: 4px;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 546px;

      .upload-content-zone {
        position: relative;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 200px;
        height: 120px;

        .upload-logo {
          width: 100%;
          height: 100%;
        }
      }

      .upload-words {
        margin-top: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;

        .click-upload-words {
          color: #0bccf6;
        }

        .drag-upload-words {
          color: #898a8f;
        }
      }

      .upload-footer {
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        max-width: 1606px;
        transform: translate(-50%, -50%);

        .upload-explains {
          flex: 1 1;
          background: rgba(22, 24, 35, 0.02);
          border-radius: 2px;
          height: 99px;
          max-width: 34rem;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -moz-box-orient: vertical;
          -moz-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding: 20px 10px;
          margin-right: 10px;
        }

        .upload-explains-title {
          padding-bottom: 1em;

          .title-words {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: #161823;
          }
        }

        .upload-explains-detail {
          padding-bottom: 1em;

          .detail-words {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: rgba(51, 51, 51, 0.5);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .upload-content-zone {
    top: 108%;
    left: 50%;
  }

  body .upload-body #dropzone {
    padding: 40px 45px;
    background: #fcfcfc;
    border: 2px dashed rgba(22, 24, 35, 0.06);
    border-radius: 4px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 26rem;
  }

  body .upload-body #dropzone .upload-content-zone {
    position: relative;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 8rem;
    height: 5rem;
  }

  body .upload-body #dropzone .upload-words {
    margin-top: 40px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(36%, 180%);
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }

  body .upload-body #dropzone .upload-footer {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    position: absolute;
    max-width: 40rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-46%, 50%);
  }

  .upload-footer {
    position: absolute;
    top: 66%;
    left: 56%;

    .upload-explains {
      width: 22rem;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .upload-content-zone {
    top: 108%;
    left: 50%;
  }

  body .upload-body #dropzone .upload-words {
    margin-top: 40px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(80%, 180%);
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }

  body .upload-body #dropzone .upload-footer {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    position: absolute;
    max-width: 55rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, 50%);
  }

  .upload-footer {
    position: absolute;
    top: 66%;
    left: 56%;

    .upload-explains {
      width: 22rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .upload-content-zone {
    top: 108%;
    left: 50%;
  }

  body .upload-body #dropzone {
    height: 30rem;
  }

  body .upload-body #dropzone .upload-content-zone {
    position: relative;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -100%);
    text-align: center;
    width: 13rem;
    height: 8rem;
  }

  body .upload-body #dropzone .upload-words {
    margin-top: 40px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -280%);
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }

  body .upload-body #dropzone .upload-footer {
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    max-width: 1606px;
    transform: translate(-50%, -150%);
  }

  .upload-words {
    top: 50%;
    left: 56%;
  }

  .upload-footer {
    position: absolute;
    top: 66%;
    left: 56%;

    .upload-explains {
      width: 22rem;
    }
  }
}

@media only screen and (min-width: 2560px) {
  .upload-content-zone {
    top: 88%;
    left: 49.5%;
  }

  .upload-words {
    top: 42%;
    left: 53.5%;
  }

  .upload-footer {
    position: absolute;
    top: 59%;
    left: 54.05%;

    .upload-explains {
      width: 34rem;
    }
  }
}
</style>
